<template>
  <v-dialog persistent max-width="1000" :value="show">
    <v-card>
      <v-card-title>
        Add game
      </v-card-title>
      <v-sheet class="px-10">
        <v-form>
          <v-row>
            <v-col cols="6" v-for="side in ['home', 'away']" :key="side + 'side'">
              <v-sheet class="text-capitalize">
                {{ side }}
              </v-sheet>
              <v-select item-value="id" item-text="club_name" :items="league ? league.clubs : []" v-model="value[side + '_club_id']">
                <template v-slot:item="{ item }">
                  <v-sheet class="d-flex flex-row align-center">
                    <v-img class="mr-5" max-width="30" max-height="30" contain :src="item.small_logo_url" />
                    <div class="text-overline">
                      {{ item.club_name }}
                    </div>
                  </v-sheet>
                </template>
                <template v-slot:selection="{ item }">
                  <v-sheet class="d-flex flex-row align-center">
                    <v-img class="mr-5" max-width="30" max-height="30" contain :src="item.small_logo_url" />
                    <div class="text-overline">
                      {{ item.club_name }}
                    </div>
                  </v-sheet>
                </template>
                <template v-slot:label>
                  Choose a team
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col 
              v-for="side in ['home', 'away']" 
              :key="side + '_side_score'" 
              class="d-flex flex-row align-center justify-center" align-self="center" cols="6"
            >
              <v-btn
                fab
                text
                x-small
                @click="value[`score_${side}`] -= 1"
                :disabled="value[`score_${side}`] < 1"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-text-field
                solo
                hide-details
                v-model="value[`score_${side}`]"
                :label="`Score ${side}`"
                style="max-width: 60px; text-align: center !important;"
              />
              <v-btn
                fab
                text
                x-small
                @click="value[`score_${side}`] = Number(value[`score_${side}`]) + 1"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-sheet>Game starttime</v-sheet>
              <VueCtkDateTimePicker
                v-model="value.starttime_unix" 
                @input="$emit('input', {...value, publishtime_unix: ( Number($event) + twoHoursInMillis ).toString() })" 
                format="YYYY-MM-DD HH:mm" 
                output-format="x"
              ></VueCtkDateTimePicker>
            </v-col>
            <v-col cols="6">
              <v-sheet>Game published</v-sheet>
              <VueCtkDateTimePicker
                v-model="value.publishtime_unix" 
                format="YYYY-MM-DD HH:mm" 
                output-format="x" />
            </v-col>
          </v-row>
          <v-select class="mt-4" label="Video protocol" :items="['hls', 'mp4']" v-model="value.video_type"></v-select>
          <v-select 
            class="mt-4"
            item-text="season_name"
            item-value="id"
            label="Season"
            :loading="loading_seasons"
            :items="unarchived_and_current_seasons(value.season_id)"
            v-model="value.season_id"
          ></v-select>
          <v-row>
            <v-col cols="12">
              <v-text-field 
                v-model="value.external_service_id"
                :label="external_service_id_name + ' id'"
              >
              </v-text-field>
              <v-text-field
                label="Video url"
                :value="value.video_url ? value.video_url : (league ? league.default_video_url : '')"
                @input="value.video_url = $event"
              >

              </v-text-field>
              <v-text-field 
                clearable 
                v-model="value.game_info" 
                counter 
                max-length="300" 
                label="Game info"
              >
                <template v-slot:append-outer>
                  <v-icon color="blue">mdi-information-outline</v-icon>
                </template>
              </v-text-field>
              <v-text-field 
                clearable 
                v-model="value.game_error" 
                counter 
                max-length="300" 
                label="Game error"
              >
                <template v-slot:append-outer>
                  <v-icon color="red">mdi-alert-outline</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
            >
              <v-switch :input-value="true" v-model="value.will_be_analyzed" label="Will be analyzed"></v-switch>
            </v-col>
            <v-col
              cols="3"
            >
              <v-switch :input-value="false" v-model="value.shown_live" label="Shown live"></v-switch>
            </v-col>
          </v-row>
        </v-form>
        <v-sheet class="d-flex mt-10 justify-center">
          <v-btn outlined color="red" class="mb-10" @click="deletingGame = true">
            Delete game
          </v-btn>
        </v-sheet>
      </v-sheet>
      <confirmation-dialog
        :show="deletingGame"
        subText="Are you sure you want to delete the game? It cannot be reversed."
        text="Delete?"
        btnColor="red"
        btnText="Yes"
        @decline="deletingGame = false"
        @accept="deleteThisGame()"
      >
      </confirmation-dialog>
      <v-card-actions>
        <v-btn
          outlined
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="primary"
          @click="$emit('save')"
        >
          Save game
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmationDialog from '../../../ConfirmationDialog.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { ConfirmationDialog },
  props: ['show', 'value', 'league'],
  created() {
    this.initSeasons( this.$route.params.league_id )
  },
  data: () => ({
    twoHoursInMillis: 1000 * 60 * 60 * 2,
    deletingGame: false
  }),
  methods: {
    ...mapActions('admin', [
      'deleteGame',
      'initSeasons'
    ]),
    ...mapActions('noti', [
      'info',
      'error'
    ]),
    deleteThisGame() {
      this.deleteGame(this.value.id)
        .then(() => {
          this.info('Peli poistettu')
          this.$emit('close')
        })
        .catch(e => {
          this.error(e)
        })
    },
  },
  computed: {
    ...mapGetters('admin', [
      'unarchived_and_current_seasons',
      'loading_seasons',
      'leagueById'
    ]),
    external_service_id_name() {
      const league = this.leagueById(this.$route.params.league_id)
      const config = league.configuration
      if(!config || typeof config !== 'string') return
      const parsed_config = JSON.parse(config)
      return parsed_config.external_sercive_id_name
    }
  }
}
</script>