<template>
  <v-card @click="$router.push(`/analysis/${game.id}`)" elevation="1" class="mt-6">
    <v-row>
      <v-col cols="2">
          <div style="height: 100%; width: 100%;" class="pl-8 d-flex flex-row align-center">
            <div>
              {{ prettyDate }}
            </div>
          </div>
      </v-col>
      <v-col cols="8">
        <v-sheet class="d-flex align-center">
          <v-img height="30" width="70" contain :src="game.home_club_logo_url"></v-img>
          <v-card-title v-if="$vuetify.breakpoint.lg">{{game.home_club_name}}</v-card-title>
          <v-card-title class="text-button" v-else>{{game.home_club_short_name}}</v-card-title>
          <v-card-title>{{game.score_home}}</v-card-title>
          <v-card-title>-</v-card-title>
          <v-card-title>{{game.score_away}}</v-card-title>
          <v-card-title v-if="$vuetify.breakpoint.lg">{{game.away_club_name}}</v-card-title>
          <v-card-title class="text-button" v-else>{{game.away_club_short_name}}</v-card-title>
          <v-img height="30" width="70" contain :src="game.away_club_logo_url"></v-img>
        </v-sheet>
      </v-col>
      <v-col cols="2" class="pr-8 d-flex flex-column align-center justify-space-around">
        <v-btn
          outlined
          @click.stop
          x-small 
          :color="game.analyzed ? 'primary' : ''"
        >
          Analyzed
        </v-btn>
        <v-btn x-small text @click.stop="$emit('edit')" small><v-icon>mdi-pencil-outline</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ['game'],
  computed: {
    prettyDate() {
      const date = new Date(this.game.starttime_unix)
      return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
    }
  }
}
</script>